import './bootstrap';
import '../../vendor/masmerise/livewire-toaster/resources/js';




document.addEventListener('livewire:navigated', () => {

    let checkbox = document.getElementById('burger');
    let div = document.getElementById('navigation');
    if(div != null){
        div.classList.remove('show');
    }
    if(checkbox != null){
        checkbox.checked = false;
        checkbox.addEventListener('change', function () {
            if (this.checked) {
                div.classList.add('show');
                document.documentElement.classList.add('no-scroll')
                document.body.classList.add('no-scroll')
            } else {
                div.classList.remove('show');
                document.documentElement.classList.remove('no-scroll')
                document.body.classList.remove('no-scroll')
            }
        });
    }
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.remove('no-scroll')




    let opensmodal = document.getElementsByClassName('opensmodal');
    for(let item of opensmodal){
        item.addEventListener('click', function (){
            if(item.classList.contains('onlyonmobile')){
                if(window.innerWidth < 768){
                    document.documentElement.classList.add('no-scroll')
                    document.body.classList.add('no-scroll')
                }
            }
            else{
                document.documentElement.classList.add('no-scroll')
                document.body.classList.add('no-scroll')
            }
        })
    }

    let closesmodal = document.getElementsByClassName('closesmodal');
    for(let item of closesmodal){
        item.addEventListener('click', function (){
            console.log('hello')
            document.documentElement.classList.remove('no-scroll')
            document.body.classList.remove('no-scroll')
        })
    }

    document.addEventListener('addScrollLock', ()=> {
        document.documentElement.classList.add('no-scroll')
        document.body.classList.add('no-scroll')
    })

    document.addEventListener('removeScrollLock', ()=> {
        document.documentElement.classList.remove('no-scroll')
        document.body.classList.remove('no-scroll')
    })

    // Share list
    const shareButton = document.getElementById('share-list-button');

    if (shareButton) {
        shareButton.addEventListener('click', async () => {
            if (navigator.share) {
                try {
                    await navigator.share({
                        title: 'Bekijk mij',
                        text: 'Bekijk ons wensenlijstje voor de baby op: ',
                        url: window.location.href
                    });
                    console.log('Successfully shared');
                } catch (error) {
                    console.error('Error sharing:', error);
                }
            } else {

                const shareBox = document.getElementById('share-box');
                shareBox.classList.add('show');


            }
        });
    }
// Focus on input field by editing profile img




    if(document.getElementById('edit-image-button') != null){
        document.getElementById('edit-image-button').addEventListener('click', function () {
            document.getElementById('file-upload-field').click();
        });
    }

    document.addEventListener('openFileBrowser', () => {
        console.log('hello');
        document.getElementById('file-upload-field').click();
    })


})






